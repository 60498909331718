<template>
  <div class="uins-page-label">
    <SearchIcon></SearchIcon>
    <input
      class="uins-page-search"
      type="text"
      v-model.trim="searchText"
      placeholder="keresés az oldalban"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SearchIcon from "@/components/SearchIcon.vue";

export default {
  name: "SearchPages",
  components: {
    SearchIcon: SearchIcon
  },
  created: function() {
    this.startLoadPagesTimer();
  },
  props: {},
  // component data properties
  data: function() {
    return {
      timer: null,
      wait: 500
    };
  },

  //watchers
  watch: {
    searchText: function() {
      this.startLoadPagesTimer();
    }
  },

  // component computed values
  computed: {
    ...mapGetters("documents", {
      getUnionDocsFiltered: "getUnionDocsFiltered",
      getDocs: "getDocs"
    }),
    ...mapGetters("parameters", {
      getParameters: "getParameters"
    }),
    ...mapGetters("searchPages", {
      getSearchText: "getSearchText",
      isValidSearchText: "isValidSearchText"
    }),

    searchText: {
      get: function() {
        return this.getSearchText;
      },
      set: function(value) {
        this.changeSearchText(value);
      }
    },

    shouldSearch: function() {
      return this.isValidSearchText;
    }
  },

  methods: {
    ...mapActions("searchPages", {
      changeSearchText: "changeSearchText",
      loadPages: "loadPages"
    }),

    removeLoadPagesTimer: function() {
      if (this.timer !== null) window.clearTimeout(this.timer);
    },

    startLoadPagesTimer: function() {
      this.removeLoadPagesTimer();
      const arg = { store: this.$store };
      this.timer = window.setTimeout(this.loadPages, this.wait, arg);
    }
  }
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally
.uins-page-label {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1rem;
  text-align: center;
  //min-width: 18em;
  min-width: 80%;
  max-width: 32em;
  height: auto;
  margin: 0px auto 0px auto;
  padding: 0 0 0 0;
  border: none;

  .uins-page-search {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 1.25em;
    width: 100%;
    height: 2.1em;
    margin: 0px;
    padding: 0.25em 0.25em 0.25em 2.25em;
    //vertical-align: middle;
    font-family: $font-family-light;
    color: $colorGrayDark;
    line-height: 110%;
    border: 1px solid $colorGrayLight;
    border-radius: 4px;

    &::placeholder {
      color: $colorGrayLight;
    }

    &:focus {
      outline-style: none;
      box-shadow: none;
      border-color: $colorRed;
    }
  }
}
</style>
