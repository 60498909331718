<template>
  <div class="uins-docs">
    <ModeChoice></ModeChoice>
    <SearchDocuments v-if="isSearchModeDocs"></SearchDocuments>
    <SearchPages v-if="isSearchModePages"></SearchPages>
    <ResultCount></ResultCount>
    <Loader></Loader>
    <SuggestedLabels v-if="isSearchModeDocs"></SuggestedLabels>
    <Result v-if="isDocsResultVisible"></Result>
    <ResultPages v-if="isSearchModePages"></ResultPages>
  </div>
</template>

<script>
import ModeChoice from "@/components/ModeChoice.vue";
import SearchDocuments from "@/components/SearchDocuments.vue";
import SearchPages from "@/components/SearchPages.vue";
import ResultCount from "@/components/ResultCount.vue";
import SuggestedLabels from "@/components/SuggestedLabels.vue";
import Result from "@/components/Result.vue";
import ResultPages from "@/components/ResultPages.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    ModeChoice,
    SearchDocuments,
    SearchPages,
    ResultCount,
    SuggestedLabels,
    Result,
    ResultPages
  },
  created: function() {
    const sendData = new URLSearchParams();
    //sendData.append("ids", "");     // errors are returned if this is enabled - IT works on it to fix this problem
    //sendData.append("labels", "");  // no results are returned if this is enabled - IT works on it to fix this problem
    this.processUrlParameters();
    this.loadDocs({ sendData: sendData, store: this.$store });
  },

  computed: {
    /*
    ...mapGetters("documents", {
      getFilteredDocs: "getFilteredDocs",
      getDocs: "getDocs"
    }),
    */
    ...mapGetters("parameters", {
      getParameters: "getParameters"
    }),
    ...mapGetters("mode", {
      isSearchModeDocs: "isSearchModeDocs",
      isSearchModePages: "isSearchModePages",
      isSearchDisabled: "isSearchDisabled"
    }),
    ...mapGetters("api", {
      getApiDocumentsWeb: "getApiDocumentsWeb",
      getApiDocsSmartCube: "getApiDocsSmartCube"
    }),

    isDocsResultVisible: function() {
      return this.isSearchDisabled || this.isSearchModeDocs;
    },

    searchIsVisible: function() {
      //return this.isSearchDocsEnabled && this.documentsAreLoaded;
      return this.isSearchModeDocs && this.documentsAreLoaded;
    },

    documentsAreLoaded: function() {
      return (
        this.getApiDocumentsWeb.length > 0 ||
        this.getApiDocsSmartCube.length > 0
      );
    }
  },
  methods: {
    ...mapActions("api", {
      loadDocs: "loadDocs"
    }),
    ...mapActions("mode", {
      processUrlParameters: "processUrlParameters"
    })
  }
};
</script>

<style lang="scss">
$maxWidth: 1280px;

.uins-docs {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-family: $font-family-light, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 1em auto 0px auto;
  padding: 0px;
  max-width: $maxWidth;
}
</style>
