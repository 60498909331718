// vuex store module
//import StringHandler from "@/plugins/StringHandler";
import ObjectHandler from "@/plugins/ObjectHandler";
import DocsHandler from "@/plugins/DocumentsHandler";
import DocHandler from "@/plugins/DocumentHandler";

export default function storeDocuments() {
  return {
    // mark as namespaced
    namespaced: true,

    // storing the loaded api properties of our application
    // the apiMenu is storing response from menu api
    // the apiPage is storing response from page api returning the content of one page
    state: {
      unwantedLabels: ["belső használatra"] // stores unwanted labels array
    },

    // getter for the state properties are used for accessing them
    getters: {
      // returns all the doc ids from the SmartCube document list
      getSmartCubeDocIds: function(state, getters, rootState, rootGetters) {
        const docs = rootGetters["api/getApiDocsSmartCube"];
        return DocsHandler.getIdsFromDocs(docs);
      },

      // documents with same ids as SmartCube doc id's are excluded from union docs
      // each document gets a new parameter: fromUnion = true
      getUnionDocsFiltered: function(state, getters, rootState, rootGetters) {
        const unionDocs = rootGetters["api/getApiDocumentsWeb"];
        const ids = getters.getSmartCubeDocIds;
        return unionDocs.reduce(function(filtered, doc) {
          const inSmartCube = ObjectHandler.hasProp(doc.id, ids);
          doc.fromUnion = true;
          if (inSmartCube === false) {
            filtered.push(doc);
          }
          return filtered;
        }, []);
      },

      // returns a merged docs array from the  Union ans SmartCube list
      // all duplicates(docs with same id) are eliminated
      getMergedDocs: function(state, getters, rootState, rootGetters) {
        const smartCubeDocs = rootGetters["api/getApiDocsSmartCube"];
        return smartCubeDocs.concat(getters.getUnionDocsFiltered);
      },

      // returns filtered documents array
      // filters based on settings ID parameters
      // filters based on labels parameter
      getFilteredDocs: function(state, getters, rootState, rootGetters) {
        const parameters = rootGetters["parameters/getParameters"];
        const ids = parameters.filterIds;
        const labels = parameters.filterLabels;
        return getters.getMergedDocs.filter(function(doc) {
          const idCheck = ids.reduce(function(passed, id) {
            return passed ? passed : doc.id + "" === id + "";
          }, false);
          const labelCheck = idCheck
            ? idCheck
            : labels.reduce(function(passed, labelsArray) {
                if (passed) {
                  return passed;
                }
                return DocHandler.docHasAllLabels(doc, labelsArray);
              }, false);
          return labelCheck;
        });
      },

      /*
      // returns the docs and marks each document with keep:true parameter if it has any of the given ids
      getDocsWithIds: function(state, getters, rootState, rootGetters) {
        const idList = rootGetters["parameters/getParameters"].filterIds;
        return DocsHandler.markByIds(getters.getMergedDocs, idList);
      },

      // returns the docs and marks each document with keep:true parameter if it has all the labels
      getDocsWithLabels: function(state, getters, rootState, rootGetters) {
        const labelList = rootGetters["parameters/getParameters"].filterLabels;
        return DocsHandler.markByLabels(getters.getDocsWithIds, labelList);
      },

      // returns all the docs marked with keep:true parameter
      getPreFilteredDocs: function(state, getters) {
        return getters.getDocsWithLabels.filter(doc => doc.keep === true);
      },
      */

      // returns true or false
      shouldReturnMergedDocs: function(state, getters, rootState, rootGetters) {
        const filteredDocs = getters.getFilteredDocs.length;
        const countIds =
          rootGetters["parameters/getParameters"].filterIds.length;
        const countLabels =
          rootGetters["parameters/getParameters"].filterLabels.length;
        return filteredDocs === 0 && countIds === 0 && countLabels === 0;
      },

      // reurns only the docs without unwanted labels
      getDocsWithoutUnwantedLabels: function(state, getters) {
        let docs = getters.shouldReturnMergedDocs
          ? getters.getMergedDocs
          : getters.getFilteredDocs;
        return DocsHandler.filterByUnwantedLabels(docs, state.unwantedLabels);
      },

      // returns the preselected docs by the following criteria
      // document has any given id
      // or the document has all the given id's
      getDocs: function(state, getters) {
        //original code
        // return getters.shouldReturnMergedDocs
        //   ? getters.getMergedDocs
        //   : getters.getFilteredDocs;
        return getters.getDocsWithoutUnwantedLabels;
      }
    },

    // mutating, changing the the state properties
    // !!! IMPORTANT: mutations are and should be always syncronous
    // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
    mutations: {},

    // actions. methods for triggering mutations
    actions: {}
  };
}
