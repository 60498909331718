import ApiService from "@/services/ApiService";

export default {
  getDocsUnionWebApi(sendData, store) {
    const apiBaseUrl = process.env.VUE_APP_API_WEB_BASEURL;
    const apiPath = process.env.VUE_APP_API_WEB_PATH + "index.php?t=23";
    return ApiService(apiBaseUrl, store, false).post(apiPath, sendData);
  },

  getDocsSmartCubeApi(sendData, store) {
    const apiBaseUrl = process.env.VUE_APP_API_SC_BASEURL;
    const apiPath = process.env.VUE_APP_API_SC_PATH;
    return ApiService(apiBaseUrl, store, false).post(apiPath, sendData);
  },

  /*getDocumentsSmartCubeApi(params, store) {
    params = params + "" === params ? params : "";
    return ApiService().get(process.env.VUE_APP_API_WEB_PATH + params);
  }*/

  getPagesUnionApi(params, store) {
    params = params + "" === params ? params : "";
    const apiBaseUrl = process.env.VUE_APP_API_PAGE_BASEURL;
    const apiPath = process.env.VUE_APP_API_PAGE_PATH + "?" + params;
    return ApiService(apiBaseUrl, store, true).get(apiPath);
  }
};
