<template>
  <div class="uins-doc-search-years">
    <div class="button" @click="clickButton">
      {{ buttonTextBig }}<span class="label">{{ buttonTextSmall }}</span>
    </div>
    <slide-up-down :active="visibleSuggestions" :duration="500" class="choices">
      <SearchYearsChoice @clickChoice="closeYears"></SearchYearsChoice>
      <SearchYearsChoice
        v-for="(year, index) in foundYears"
        :key="'year' + index"
        :year="year"
        @clickChoice="closeYears"
      ></SearchYearsChoice>
    </slide-up-down>
  </div>
</template>

<script>
import SearchYearsChoice from "@/components/SearchYearsChoice.vue";
import { mapGetters, mapActions } from "vuex";
import textGenerator from "@/plugins/TextGenerator.js";
import objectHandler from "@/plugins/ObjectHandler.js";

export default {
  name: "SearchYears",
  components: {
    SearchYearsChoice: SearchYearsChoice
  },

  // component data properties
  data: function() {
    return {
      allYears: "Bármikor hatályos",
      inEffect: "hatályos",
      buttonClicked: false
    };
  },

  // component computed values
  computed: {
    ...mapGetters("searchDocs", {
      foundYears: "foundYears",
      getSearchYear: "getSearchYear"
    }),

    yearsWereFound: function() {
      return this.foundYears.length > 0;
    },

    visibleSuggestions: function() {
      //return this.years.length > 0 && this.buttonClicked;
      return this.yearsWereFound && this.buttonClicked;
    },

    buttonTextBig: function() {
      return this.getSearchYear !== null ? this.getSearchYear + "" : "";
    },

    buttonTextSmall: function() {
      return this.getSearchYear !== null
        ? this.makeYearSuffix(this.getSearchYear) + " " + this.inEffect
        : this.allYears;
    }
  },

  methods: {
    ...mapActions("searchDocs", {
      changeSearchYear: "changeSearchYear"
    }),

    clickButton: function() {
      this.buttonClicked =
        this.yearsWereFound && this.buttonClicked ? false : true;
    },

    resetButtonClicked: function() {
      this.buttonClicked = false;
    },

    makeYearSuffix: function(year) {
      return "-" + textGenerator.getNumberSuffixHun(year, "ban");
    },

    closeYears: function() {
      this.resetButtonClicked();
    }
  },

  watch: {
    foundYears: function() {
      const keepIt = objectHandler.arrayHasValue(
        this.foundYears,
        this.getSearchYear
      );
      if (keepIt === false && this.getSearchYear !== null) {
        this.changeSearchYear(null);
      }
      this.closeYears();
    }
  }
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally

.uins-doc-search:focus + .uins-doc-search-years {
  .button {
    border-color: $colorRed;
    border-left: none;
  }
}

.uins-doc-search-years {
  position: absolute;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  top: 0px;
  right: 0px;
  margin: 0px;
  padding: 0px;
  width: 5em;
  height: 2.625em;
  text-align: center;
  z-index: 1;

  .button {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: inherit;
    height: inherit;
    padding: 0.5em 0.5em 0.25em 0.5em;
    margin: 0px;
    font-size: 0.85em;
    font-family: $font-family-normal;
    line-height: 100%;
    color: $colorWhite;

    text-transform: uppercase;
    background-color: $colorGrayLight;
    cursor: pointer;

    border-top: 1px solid $colorGrayLight;
    border-right: 1px solid $colorGrayLight;
    border-bottom: 1px solid $colorGrayLight;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    .label {
      position: relative;
      display: inline;
      font-size: 0.75em;
      line-height: 100%;
      margin: 0px;
      padding: 0px;
    }

    &:hover {
      background-color: $colorGray;
    }
  }

  .choices {
    position: absolute;
    display: block;
    box-sizing: border-box;
    top: 2.625em;
    right: 0px;
    width: inherit;
    height: auto;
    margin: -1px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    background-color: $colorWhite;
    box-shadow: 0px 0.25em 0.5em rgba(0, 0, 0, 0.5);
    //border: 1px solid $colorGrayLight;
    overflow: hidden;
  }
}
</style>
