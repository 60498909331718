// vuex store module

export default function storeMode() {
  return {
    // mark as namespaced
    namespaced: true,

    // storing searchMode for app
    // 2 functioning modes:
    // - search mode (one of the searchmodes has to be enabled)
    //      - docs search enabled/disabled
    //      - pages search disabled/enabled
    // - document list mode
    //      - docs search disabled
    //      - pages search disabled
    state: {
      searchModeDocs: false,
      searchModePages: true
    },

    // getters for the state properties are used for accessing them
    getters: {
      isSearchModeDocs: function(state, getters, rootState) {
        const isEnabled = rootState.parameters.isSearchDocsEnabled;
        const canSwitch = getters.canChangeSearchMode;
        return canSwitch ? state.searchModeDocs && isEnabled : isEnabled;
      },

      isSearchModePages: function(state, getters, rootState) {
        const isEnabled = rootState.parameters.isSearchPagesEnabled;
        const canSwitch = getters.canChangeSearchMode;
        return canSwitch ? state.searchModePages && isEnabled : isEnabled;
      },

      isSearchDisabled: function(state, getters, rootState) {
        const noDocsSearch = !rootState.parameters.isSearchDocsEnabled;
        const noPagesSearch = !rootState.parameters.isSearchPagesEnabled;
        return noDocsSearch && noPagesSearch;
      },

      // search mode can be changed only if parameters allow choosing both page and document search
      canChangeSearchMode: function(state, getters, rootState) {
        const hasDocsSearch = rootState.parameters.isSearchDocsEnabled;
        const hasPagesSearch = rootState.parameters.isSearchPagesEnabled;
        return hasDocsSearch && hasPagesSearch;
      },

      // returns true if url parameter searchMode is set to docs
      isUrlSearchModeDocuments: function(state, getters, rootState) {
        return rootState.parameters.urlSearchMode === "docs";
      },

      // returns true if url searchMode is set to pages
      isUrlSearchModePages: function(state, getters, rootState) {
        return rootState.parameters.urlSearchMode === "pages";
      }
    },

    // mutating, changing the the state properties
    // !!! IMPORTANT: mutations are and should be always syncronous
    // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
    mutations: {
      // changes the apiMenu state by replacing it with a new array
      setSearchModeDocs: function(state, newValue) {
        state.searchModeDocs = newValue === true ? newValue : false;
      },

      setSearchModePages: function(state, newValue) {
        state.searchModePages = newValue === true ? newValue : false;
      }
    },

    // actions. methods for triggering mutations
    actions: {
      switchToSearchModePages: function(context) {
        context.commit("setSearchModePages", true);
        context.commit("setSearchModeDocs", false);
      },

      switchToSearchModeDocs: function(context) {
        context.commit("setSearchModeDocs", true);
        context.commit("setSearchModePages", false);
      },

      processUrlParameters: function(context) {
        const docsSearch = context.rootState.parameters.isSearchDocsEnabled;
        const pagesSearch = context.rootState.parameters.isSearchPagesEnabled;
        if (context.getters.isUrlSearchModeDocuments && docsSearch) {
          context.dispatch("switchToSearchModeDocs");
          context.dispatch("setDocumentsSearchText");
        }
        if (context.getters.isUrlSearchModePages && pagesSearch) {
          context.dispatch("switchToSearchModePages");
          context.dispatch("setPagesSearchText");
        }
      },

      setPagesSearchText: function({ dispatch, rootState }) {
        const text = rootState.parameters.urlSearchText;
        dispatch("searchPages/changeSearchText", text, { root: true });
      },

      setDocumentsSearchText: function({ dispatch, rootState }) {
        const text = rootState.parameters.urlSearchText;
        dispatch("searchDocs/changeSearchText", text, { root: true });
      }
    }
  };
}
