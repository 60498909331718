import Vue from "vue";
import App from "@/App.vue";
import makeStore from "@/store/store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLogger from "vuejs-logger";
import SlideUpDown from "vue-slide-up-down";
// import global component files from current project, and register them
import Loader from "@/components/Loader.vue";
import VueContainerHandler from "@/plugins/VueContainerHandler.js";
import VueContainerData from "@/plugins/VueContainerData.js";

// register components
Vue.component("slide-up-down", SlideUpDown);
Vue.component("Loader", Loader);

//create settings for vendor components
const isProduction = process.env.NODE_ENV === "production";
const vueLoggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};

// register vendor components globally,
// this way no import is needed befor using in Vue
Vue.use(VueLogger, vueLoggerOptions);
Vue.use(VueAxios, axios);

// creating the event bus instance
export const EventBus = new Vue();

Vue.config.productionTip = false;

// **************************************
// creating multiple vue instances
const containerIds = VueContainerHandler.getContainerIds();
const appInstances = [];
for (let k = 0; k < containerIds.length; k++) {
  const id = containerIds[k];
  const container = document.getElementById(id);
  const data = VueContainerData.ContainerData(container, id);
  if (data.valid === true) {
    const appInstance = new Vue({
      el: "#" + containerIds[k],
      store: makeStore(data),
      data: data,
      render: h => h(App)
    });
    appInstances.push(appInstance);
  }
}
export default appInstances;
