<template>
  <a class="uins-file" :href="fileUrl" target="_blank" :class="fileType">
    <span class="doc-title">{{ docTitle }}</span>
    <span class="file-dates">
      <span class="date-label" v-if="hasInEffectLabel">{{
        inEffectLabel
      }}</span>
      <span class="date-label">{{ formattedDateFrom }}</span>
      <span class="date-label" v-if="isVisibleDateTo">{{ dateToLabel }}</span>
    </span>
    <DocFileInfo v-if="docinfo.showInfo" :docinfo="docinfo"></DocFileInfo>
    <Legal :labels="docLabels" v-if="!shouldHideLegalText"></Legal>
  </a>
</template>

<script>
import objectHandler from "@/plugins/ObjectHandler.js";
import textGenerator from "@/plugins/TextGenerator.js";
import { mapGetters } from "vuex";

import fileHandler from "@/plugins/FileHandler.js";
import Legal from "@/components/Legal.vue";
import DocFileInfo from "@/components/DocumentFileInfo.vue";

// the keys required in the docInfo props object
const docInfoKeys = ["docId", "title", "labels", "showInfo", "fromUnion"];

export default {
  name: "DocumentFile",
  components: {
    Legal,
    DocFileInfo
  },
  created: function() {},
  props: {
    file: {
      type: Object,
      required: true,
      default: function() {
        return {};
      }
    },
    docinfo: {
      type: Object,
      required: true,
      validator: config => docInfoKeys.every(key => key in config)
    }
  },

  // component data properties
  data: function() {
    return {
      inEffect: {
        current: "Hatályos",
        archive: "Hatályos volt",
        future: "Hatályba lép"
      },
      // documents with these special labels need differnt date label handling
      noValidityLabels: ["határozat"] //közlemény
    };
  },

  // component computed values
  computed: {
    ...mapGetters("searchDocs", {
      getSearchYear: "getSearchYear"
    }),

    docTitle: function() {
      //return this.title;
      return this.docinfo.title;
    },

    docLabels: function() {
      return objectHandler.returnArray(this.docinfo.labels);
    },

    /*info: function() {
      const source = this.docinfo.fromWeb === true ? "U" : "SC";
      const separator = this.docinfo.docId === "" ? "" : "-";
      return source + separator + this.docinfo.docId;
    },*/

    // in case of slide down archive files legal text shold not be displayed
    shouldHideLegalText: function() {
      //this.$log.debug(this.$vnode.key);
      return this.$vnode.key.slice(0, 7) === "archive";
    },

    // the file should display only one single date without sufixes and prefixes
    hasInEffectLabel: function() {
      const docLabels = this.docLabels;
      const shouldHide = this.noValidityLabels.reduce(function(result, label) {
        return result || docLabels.indexOf(label) >= 0;
      }, false);
      return !shouldHide;
    },

    //
    hasFilePathDomain: function() {
      //this.$log.debug(this.filePath.slice(0, 8));
      return this.filePath.slice(0, 8) === "https://";
    },

    filePath: function() {
      return objectHandler.getProperty("", "path", this.file);
    },

    fileUrl: function() {
      const domain = this.hasFilePathDomain ? "" : "https://union.hu";
      return domain + this.filePath;
    },

    isExpired: function() {
      return fileHandler.isExpired(this.file);
    },

    isFuture: function() {
      return fileHandler.isFuture(this.file);
    },

    // returns apropriate label text according to expiry
    inEffectLabel: function() {
      if (this.isExpired) return this.inEffect.archive;
      if (this.isFuture) return this.inEffect.future;
      return this.inEffect.current;
    },

    hasFileDateFrom: function() {
      return fileHandler.getStringDateFrom(this.file) !== "";
    },

    hasFileDateTo: function() {
      return fileHandler.getStringDateTo(this.file) !== "";
    },

    fileYearFrom: function() {
      return textGenerator.getYearNumFromDate8Digit(this.fileDateFrom);
    },

    fileYearTo: function() {
      return textGenerator.getYearNumFromDate8Digit(this.fileDateTo);
    },

    fileDateFrom: function() {
      return fileHandler.getDateFrom(this.file);
    },

    fileDateTo: function() {
      return fileHandler.getDateTo(this.file);
    },

    formattedDateFrom: function() {
      const fromattedDate = textGenerator.formatHunFromDate8Digit(
        this.fileDateFrom,
        true
      );
      const dateSuffix = this.hasInEffectLabel ? "-" + this.suffixDateFrom : "";
      return fromattedDate + dateSuffix;
    },

    formattedDateTo: function() {
      const result = textGenerator.formatHunFromDate8Digit(
        this.fileDateTo,
        true
      );
      return result + "-ig";
    },

    dateToLabel: function() {
      return this.hasFileDateTo ? this.formattedDateTo : "";
    },

    isVisibleDateTo: function() {
      return this.dateToLabel !== "" && this.hasInEffectLabel;
    },

    /*formattedDates: function() {
      const dateTo = this.hasFileDateTo ? this.formattedDateTo : "";
      return this.formattedDateFrom + " " + dateTo;
    },*/

    suffixDateFrom: function() {
      // return textGenerator.getNumberSuffixHun(this.fileDateFrom % 10, "tol");
      //correction: if it is 10, "től" is needed!
      return this.fileDateFrom % 100 === 10
        ? textGenerator.getNumberSuffixHun(10, "tol")
        : textGenerator.getNumberSuffixHun(this.fileDateFrom % 10, "tol");
    },

    fileNote: function() {
      return objectHandler.getProperty("", "note", this.file);
    },

    fileType: function() {
      return objectHandler.getProperty("", "type", this.file);
    }
  },

  filters: {},

  methods: {},

  watch: {}
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally

.uins-file {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0.5em 0px 0px 0px;
  padding: 0.5em 0.5em 1em 4.25em;
  width: 100%;
  min-height: 3.75em;
  text-decoration: none;

  &.hidden {
    display: none;
  }

  &:before {
    position: absolute;
    display: block;
    box-sizing: border-box;
    content: "";
    font-size: 0.5em;
    top: 0;
    left: 0px;
    width: 5em;
    height: 6em;
    margin: 0px;
    padding: 2.5em 0px 0px 0px;
    font-family: $font-family-basic;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.125em;
    text-transform: uppercase;
    border-radius: 10% 40% 10% 10%;
    color: #ffffff;
    background-color: $colorGrayLight;
    overflow: hidden;
  }

  &.pdf:before {
    content: "pdf";
  }

  &.doc:before {
    content: "doc";
  }

  &.xls:before {
    content: "xls";
  }

  &.docx:before {
    content: "docx";
  }

  &.xlsx:before {
    content: "xlsx";
  }

  &.ppt:before {
    content: "ppt";
  }

  &.pptx:before {
    content: "pptx";
  }

  &.archive:before {
    background-color: $paletteCreme1;
    border: 1px solid $paletteCreme1;
    color: $colorGray;
  }

  &.planned:before {
    background-color: $paletteBlue1;
    border: 1px dashed $paletteBlue3;
    color: $paletteBlue5;
  }

  .doc-title {
    position: relative;
    display: block;
    box-sizing: border-box;
    min-height: 1em;
    margin: 0px;
    padding: 0px;
    font-size: 1em;
    line-height: 100%;
    font-family: $font-family-light;
    color: $colorGrayDark;
    text-decoration: none;
  }

  &.archive .file-dates .date-label {
    border-color: $paletteCreme2;
    //background-color: $paletteCreme1;
  }

  &.planned .file-dates .date-label {
    color: $paletteBlue5;
    //background-color: $paletteBlue1;
    border-color: $paletteBlue3;
  }

  .file-dates {
    display: block;
    margin: 0;
    padding: 0.25em 0px 0px 0px;
    font-size: 0.75em;
    color: $colorGrayDark;
    text-transform: uppercase;

    .date-label {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      font-size: 1em;
      padding: 0.15em 0.5em 0.15em 0.5em;
      margin: 0px 0.5em 0px 0px;
      border-radius: 3px;
      //background-color: #cffadc;
      border: 1px solid $colorGrayLight;
      color: $colorGrayDark;
    }
  }

  .file-note {
    display: block;
    margin: 0;
    font-size: 0.75em;
    color: $colorGrayDark;
  }

  &:hover {
    .doc-title {
      color: $colorRed;
    }
    .file-note {
      color: $colorRed;
    }
    &:before {
      background-color: $colorRed;
    }

    .file-dates .date-label {
      //border-color: $colorRed;
      color: $colorRed;
    }
  }

  &.archive:hover {
    &:before {
      background-color: $paletteCreme1;
      border: 1px solid $colorRed;
      color: $colorRed;
    }
  }

  &.planned:hover {
    &:before {
      background-color: $paletteBlue1;
      border: 1px dashed $colorRed;
      color: $colorRed;
    }
  }
}
</style>
