<template>
  <div class="uins-file-info">
    <span class="info-id">{{ infoId }}</span>
    <span
      v-for="(docLabel, index) in docLabels"
      class="info-label"
      v-bind:key="'info' + index"
      >{{ docLabel }}</span
    >
  </div>
</template>

<script>
// the keys required in the docInfo props object
const docInfoKeys = ["docId", "title", "labels", "showInfo", "fromUnion"];

export default {
  name: "DocumentFileInfo",
  components: {},
  created: function() {},
  props: {
    docinfo: {
      type: Object,
      required: true,
      validator: config => docInfoKeys.every(key => key in config)
    }
  },

  // component data properties
  data: function() {
    return {};
  },

  // component computed values
  computed: {
    docLabels: function() {
      return this.docinfo.labels;
    },

    infoId: function() {
      const source = this.docinfo.fromUnion === true ? "U" : "SC";
      const separator = this.docinfo.docId === "" ? "" : "-";
      return source + separator + this.docinfo.docId;
    }
  },

  filters: {},

  methods: {},

  watch: {}
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally

.uins-file-info {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0.25em 0px 0px 0px;
  padding: 0;
  //padding: 0.5em 0.5em 0em 0.5em;
  width: 100%;
  text-decoration: none;

  /*&:after {
    position: relative;
    display: block;
    box-sizing: border-box;
    content: $font_path;
  }*/

  .info-id {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 0.75em;
    padding: 0.15em 0.5em 0.15em 0.5em;
    margin: 0;
    border-radius: 3px;
    background-color: $colorGrayLight;
    border: 1px solid $colorWhite;
    color: $colorWhite;
  }

  .info-label {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    font-size: 0.75em;
    padding: 0.15em 0.5em 0.15em 0.5em;
    margin: 0;
    border-radius: 3px;
    background-color: $colorGrayLight;
    border: 1px solid $colorWhite;
    color: $colorWhite;
  }
}
</style>
