<template>
  <div class="uins-page-search-result">
    <Page
      v-for="(page, index) in resultChunk"
      v-bind:key="'page-' + index"
      v-bind:page="page"
      v-bind:observe="shouldUseIntersectionObserver(index)"
      v-on:requestingMoreResults="increaseShownResults"
      v-on:requestingAllResults="maximiseShownResults"
    ></Page>
  </div>
</template>

<script>
import Page from "@/components/Page.vue";
import { mapGetters } from "vuex";

export default {
  name: "ResultPages",
  components: {
    Page: Page
  },

  data: function() {
    return {
      shownResults: 10
    };
  },

  watch: {},

  // component computed values
  computed: {
    ...mapGetters("searchPages", {
      getFilteredPageHits: "getFilteredPageHits"
    }),

    resultChunk: function() {
      return this.getFilteredPageHits.filter(function(item, index) {
        return index < this.shownResults;
      }, this);
    },

    hasHiddenResults: function() {
      return this.resultChunk.length < this.getFilteredPageHits.length;
    }
  },

  methods: {
    shouldUseIntersectionObserver: function(index) {
      return this.hasHiddenResults && index === this.resultChunk.length - 1;
    },

    increaseShownResults: function() {
      const resultsLimit = Math.max(this.getFilteredPageHits.length, 10);
      this.shownResults = Math.min(this.shownResults + 10, resultsLimit);
    },

    maximiseShownResults: function() {
      this.shownResults = this.getFilteredPageHits.length;
    }
  }
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally

.uins-page-search-result {
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: 0 auto 0 auto;
  padding: 1em 0px 0px 0px;
  text-align: left;
  max-width: 100%;
}
</style>
