// vuex store module
//import StringHandler from "@/plugins/StringHandler";
import ObjectHandler from "@/plugins/ObjectHandler";

export default function storeParameters(data) {
  //console.log(data);
  return {
    // mark as namespaced
    namespaced: true,

    // storing the loaded api properties of our application
    // the apiMenu is storing response from menu api
    // the apiPage is storing response from page api returning the content of one page
    state: {
      id: ObjectHandler.getProp("", "id", data),
      isValid: ObjectHandler.getProp(false, "valid", data),
      isSearchDocsEnabled: ObjectHandler.getProp(false, "search", data),
      isSearchPagesEnabled: ObjectHandler.getProp(false, "searchPages", data),
      filterIds: ObjectHandler.getProp([], "filterIds", data),
      filterLabels: ObjectHandler.getProp([], "filterLabels", data),
      categories: ObjectHandler.getProp([], "categories", data),
      showInfo: ObjectHandler.getProp(false, "showInfo", data),
      urlSearchText: ObjectHandler.getProp("", "urlSearchText", data),
      urlSearchMode: ObjectHandler.getProp("", "urlSearchMode", data),

      showValid: true,
      showPlanned: true,
      showArchived: true,
      dateFrom: null,
      dateTo: null
    },

    // getter for the state properties are used for accessing them
    getters: {
      // returns the current value of the apiDocuments state
      getParameters: function(state) {
        return state;
      }
    },

    // mutating, changing the the state properties
    // !!! IMPORTANT: mutations are and should be always syncronous
    // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
    mutations: {},

    // actions. methods for triggering mutations
    actions: {}
  };
}
