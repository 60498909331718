// vuex store module
// intended for loader functionality, used in conjunction with axios interceptors

export default {
  // mark as namespaced
  namespaced: true,

  // storing all the properties of our application
  // the 'loading' parameter at 0 value means nothing is loading, while it is greater than 0 means that loading is in progress
  state: {
    loading: 0
  },

  // getter for the state properties are used for accessing them
  getters: {
    // returns the current value of the loading state
    isLoading: function(state) {
      return state.loading > 0;
    }
  },

  // mutating, changing the the state properties
  // !!! IMPORTANT: mutations are and should be always syncronous
  // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
  mutations: {
    // changes the loading state by incrementing loading state by one
    startLoading: function(state) {
      state.loading = state.loading >= 0 ? state.loading + 1 : 1;
    },

    // changes the loading state by decrementing loading state by one
    // commiting loader/stopLoading with slight delay
    // (this way if a load starts immediatly after a load finished the loading status will not flip fast )
    stopLoading: function(state) {
      window.setTimeout(function() {
        state.loading = Math.max(state.loading - 1, 0);
      }, 100);
    }
  },

  // actions. methods for triggering mutations
  actions: {}
};
