<template>
  <div class="doc-archive-buttons">
    <div
      class="button-archive"
      v-on:click="archiveButtonClick"
      v-bind:class="{ active: clicked }"
      v-bind:title="description"
    >
      {{ buttonText }}
    </div>
    <!--div
      class="button-archive"
      v-for="(year, index) in years"
      v-bind:key="'year' + index"
    >
      {{ year }}<span class="small">{{ yearSuffix(year) }}</span>
    </!--div-->
  </div>
</template>

<script>
import TextGenerator from "@/plugins/TextGenerator.js";

export default {
  name: "ArchiveButton",
  props: {
    years: Array
  },
  // component data properties
  data: function() {
    return {
      clicked: false,
      textShow: "Korábbiak megtekintése",
      textHide: "Korábbiak elrejtése"
    };
  },

  // computed properties
  computed: {
    description: function() {
      return !this.clicked
        ? "Korábban érvényes verziók megjelenítése."
        : "Korábban érvényes verziók elrejtése.";
    },

    buttonText: function() {
      return this.clicked ? this.textHide : this.textShow;
    }
  },

  // component methods
  methods: {
    yearSuffix: function(year) {
      return "-" + TextGenerator.getNumberSuffixHun(year, "bol");
    },

    archiveButtonClick: function() {
      this.clicked = !this.clicked;
      this.$emit("archiveButton-clicked", this.clicked);
    }
  }
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally
.uins-doc {
  .doc-archive-buttons {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 1em;
    margin: -0.75em 0px 0em 4.25em;
    padding: 0px;

    .button-archive {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      font-size: 0.75em;
      //top: 0.5em;
      //right: 0px;
      //width: 8.5em;
      margin: 0px;
      padding: 0.35em 2em 0.25em 0.5em;
      text-transform: uppercase;
      text-align: center;
      font-family: "LatoWebLight", Helvetica, sans-serif;
      color: $colorWhite;
      background-color: $colorGrayLight;
      z-index: 1;
      border-radius: 3px;
      //border: 1px solid $colorGrayLight;
      line-height: 100%;
      cursor: pointer;
      transition-duration: 0.25s;
      transition-property: color, background-color, border-color;

      &.active {
        color: $colorWhite;
        background-color: $colorGray;
        border-color: $colorGrayLight;
      }

      &:hover {
        //border-color: $colorGrayDarkest;
        //color: $colorGrayDark;
        background-color: $colorGray;
      }

      /*&:first-child {
        padding-right: 1.75em;

        &:after {
          position: absolute;
          display: block;
          box-sizing: content-box;
          content: "";
          font-size: 1em;
          top: 0.2em;
          right: 0.25em;
          width: 1.15em;
          height: 1.15em;
          transform: rotate(45deg);
          border-top: 1px solid $colorWhite;
          border-right: 1px solid $colorWhite;
        }
      }*/

      .small {
        position: relative;
        display: inline;
        margin: 0px;
        padding: 0px;
        font-size: 0.75em;
        line-height: 100%;
      }

      &:before {
        position: absolute;
        display: block;
        box-sizing: content-box;
        content: "";
        font-size: 1em;
        line-height: 100%;
        top: 0.5em;
        right: 0.5em;
        width: 0.5em;
        height: 0.5em;
        padding: 0px;
        margin: 0px;
        //background-color: $colorGray;
        border-top: 1px solid $colorWhite;
        border-right: 1px solid $colorWhite;
        transition-duration: 0.25s;
        transition-property: right;
        transform: rotate(45deg);
        transform-origin: 50% 50%;
      }

      &:after {
        position: absolute;
        display: block;
        box-sizing: content-box;
        content: "";
        font-size: 1em;
        line-height: 100%;
        top: 0.5em;
        right: 0.25em;
        width: 0.5em;
        height: 0.5em;
        padding: 0px;
        margin: 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0);
        border-left: 1px solid rgba(255, 255, 255, 0);
        transition-duration: 0.25s;
        transition-property: right;
        transform: rotate(45deg);
        transform-origin: 50% 50%;
      }

      &.active:after {
        right: 0.25em;
        border-bottom: 1px solid $colorWhite;
        border-left: 1px solid $colorWhite;
        transition-duration: 0.25s;
        transition-property: right, border;
      }

      &.active:before {
        right: 1em;
        transition-duration: 0.25s;
        transition-property: right;
      }
    }
  }
}
</style>
