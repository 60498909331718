export default {
  /**
   * check if a value is string
   * @param {string} url value to bechecked
   * @return {boolean>} returns true if is string false if not
   */
  isString: function(url) {
    return url + "" === url;
  },

  /**
   * getting the protocol type from window.location
   * @return {string>} returns the protocol part of the url (http: or https:)
   */
  getUrlProtocol: function() {
    return window.location.protocol;
  },

  /**
   * getting the domain name from window.location
   * @return {string>} returns the domain name (.something.com)
   */
  getUrlDomain: function() {
    return window.location.hostname;
  },

  /**
   * getting the port number from window.location
   * @return {string>} returns the protocol port number
   */
  getUrlPort: function() {
    return window.location.port;
  },

  /**
   * URL decoding strings
   * @param {string} param value to be decoded
   * @return {string>} returns decoded string or param converted to string
   */
  //decodes a query parameter (decodeURIComponent cannot be used directly on query parameters)
  decodeQueryParameter: function(param) {
    try {
      return decodeURIComponent(param.replace(/\+/g, " "));
    } catch (e) {
      return param + "";
    }
  },

  /**
   * returns the current window search query
   * @return {string>} returns query string without the question mark
   */
  getUrlQuery: function() {
    const query = window.location.search.replace(/^\?/, "");
    return query;
  },

  /**
   * extract name key and associated value from string
   * @param {string} nameValueStr name & value pairs in this pattern name=value
   * @return {Object<{paramName: string, paramValue:(string|null)}>} returns object
   */
  objFromNameValueString: function(nameValueStr) {
    const nameValueArr = this.isString(nameValueStr)
      ? nameValueStr.split("=")
      : [];
    const name = this.isString(nameValueArr[0]) ? nameValueArr[0] : "";
    const value = this.isString(nameValueArr[1]) ? nameValueArr[1] : null;
    return {
      paramName: name,
      paramValue: value
    };
  },

  /**
   * returns array of objects containing paramName & paramValue pairs from a given urlSearchQuery
   * @param {string} urlQuery the search query string we are colleclecting the parameters from
   * @return {Array<{string:(string|null)}>} returns array, with key value pair objects
   */
  getUrlParamsFromQuery: function(urlQuery) {
    const splitQuery = urlQuery.toString().split("&");
    const self = this;
    return splitQuery.reduce(function(result, param) {
      const obj = self.objFromNameValueString(param);
      if (self.isString(obj.paramName) && obj.paramName.length > 0) {
        result[obj.paramName] = self.decodeQueryParameter(obj.paramValue);
      }
      return result;
    }, {});
  },

  /**
   * returns the parameter value from an urlQuery string
   * @param {string} paramName the name of the paramater searched in the query string
   * @param {string} urlQuery the query string where we want to find the parameter
   * @return {(string | null)} returns string if value is found or null
   */
  getUrlParamValue: function(paramName, urlQuery) {
    const params = this.getUrlParamsFromQuery(urlQuery);
    return Object.prototype.hasOwnProperty.call(params, paramName)
      ? params[paramName]
      : null;
  },

  /**
   * returns a parameter value from the current window location
   * @param {string} paramName the name of the paramater searched in the query string
   * @return {(string | null)} returns string if value is found or null
   */
  getLocationParamValue: function(paramName) {
    const query = this.getUrlQuery();
    return this.getUrlParamValue(paramName, query);
  }
};
