import axios from "axios";
//import store from "@/store/store";

// API base settings
export default function(baseURL, store, isPrivate) {
  //console.log(token);
  const http = axios.create({
    // base url that we are connecting to
    baseURL: baseURL,
    //baseURL === baseURL + "" ? baseURL : process.env.VUE_APP_API_WEB_BASEURL,
    // no authentication is needed
    withCredentials: false,
    headers: {
      // the MIME-type the browser is going to look for
      Accept: "text/json",
      // the data type we are sending send needed for POST
      // IMPORTANT set this value or the data won't get through !!!
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    }
    //cancelToken: token.token
  });

  //interceptor for detecting requests in progress
  http.interceptors.request.use(
    //handling start loading
    function(config) {
      setStartLoaderState(store, isPrivate);
      return config;
    },

    // handling error
    // commiting loader/stopLoading
    function(error) {
      setStopLoaderState(store, isPrivate);
      return Promise.reject(error);
    }
  );

  //interceptor for detecting requests in progress
  http.interceptors.response.use(
    //handling  successfully finished loading
    // commiting loader/stopLoading
    function(response) {
      setStopLoaderState(store, isPrivate);
      return response;
    },

    //handling error
    // commiting loader/stopLoading
    function(error) {
      setStopLoaderState(store, isPrivate);
      return Promise.reject(error);
    }
  );

  return http;
}

function setStartLoaderState(store, isPrivate) {
  isPrivate === true
    ? store.commit("loaderPrivate/startLoading")
    : store.commit("loader/startLoading");
}

function setStopLoaderState(store, isPrivate) {
  isPrivate === true
    ? store.commit("loaderPrivate/stopLoading")
    : store.commit("loader/stopLoading");
}
