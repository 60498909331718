<template>
  <div class="uins-doc-search-result">
    <Document
      v-for="(doc, index) in resultChunk"
      v-bind:key="'doc-' + index"
      v-bind:doc="doc"
      v-bind:observe="shouldUseIntersectionObserver(index)"
      v-on:requestingMoreResults="increaseShownResults"
      v-on:requestingAllResults="maximiseShownResults"
    ></Document>
  </div>
</template>

<script>
import Document from "@/components/Document.vue";
import { mapGetters } from "vuex";

export default {
  name: "Result",
  components: {
    Document: Document
  },

  data: function() {
    return {
      shownResults: 10
    };
  },

  watch: {
    result: function() {
      // reseting showResults to default value
      // this.$log.debug("result changed");
      this.shownResults = 10;
    }
  },

  // component computed values
  computed: {
    ...mapGetters("parameters", {
      getParameters: "getParameters"
    }),
    ...mapGetters("documents", {
      getDocs: "getDocs"
    }),
    ...mapGetters("searchDocs", {
      searchResult: "searchResult"
    }),

    result: function() {
      return this.getParameters.isSearchDocsEnabled
        ? this.searchResult
        : this.getDocs;
    },

    // returns only a specified part of the result
    // so we do not have sluggish performance for large sets of results
    // we will enable more result items based on scroll, using IntersectionObserver
    resultChunk: function() {
      return this.result.filter(function(elem, index) {
        return index < this.shownResults;
      }, this);
    },

    hasHiddenResults: function() {
      return this.resultChunk.length < this.result.length;
    }
  },

  methods: {
    shouldUseIntersectionObserver: function(index) {
      return this.hasHiddenResults && index === this.resultChunk.length - 1;
    },

    increaseShownResults: function() {
      const resultsLimit = Math.max(this.result.length, 10);
      this.shownResults = Math.min(this.shownResults + 10, resultsLimit);
    },

    maximiseShownResults: function() {
      this.shownResults = this.result.length;
    }
  }
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally

.uins-doc-search-result {
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: 0 auto 0 auto;
  padding: 1em 0px 0px 0px;
  text-align: left;
  max-width: 100%;
}
</style>
