// document files hadler plugin

// import heplers
import objHandler from "@/plugins/ObjectHandler.js";
import fileHandler from "@/plugins/FileHandler.js";
//import { getCurrentInstance } from "@vue/composition-api";

// exporting methods
export default {
  // returns all years from a document's files
  //getYearsInFiles: function(files) {
  getYears: function(files) {
    const years = objHandler.forceArray(files).map(function(file) {
      return fileHandler.getYearsList(file);
    }, this);
    const flattened = objHandler.flattenArray(years);
    return flattened;
  },

  // order files by dateFrom
  // orderDocumentFiles: function(filesArray) {
  orderFiles: function(filesArray) {
    const resultArray = objHandler.forceArray(filesArray).slice();
    //return resultArray.slice().sort(this.compareDocumentFiles);
    return resultArray.sort(
      (fileA, fileB) =>
        fileHandler.getDateFrom(fileB) - fileHandler.getDateFrom(fileA)
    );
  },

  //sortDocumentFiles: function(filesArray) {

  // sorts the files based on expiration and
  sortFiles: function(filesArray) {
    // should try using reduce
    const reduced = filesArray.reduce(
      function(result, file) {
        if (fileHandler.isFuture(file)) {
          result.future.push(file);
        } else if (fileHandler.isCurrent(file)) {
          if (result.current.length === 0) {
            result.current.push(file);
          } else {
            result.expired.push(file);
          }
        } else {
          result.expired.push(file);
        }
        return result;
      },
      { future: [], current: [], expired: [] }
    );
    if (reduced.current.length === 0 && reduced.expired.length > 0) {
      reduced.current.push(reduced.expired.shift());
    }
    //console.log(reduced);
    return reduced;

    /*
    //console.log("sortFiles", fileHandler);
    const future = filesArray.filter(fileHandler.isFuture, fileHandler); // future files
    var current = filesArray.filter(fileHandler.isCurrent, fileHandler); // current files, should be always one file, if more the rest should be transfered to expired
    //console.log(current);
    const expired = filesArray.filter(fileHandler.isExpired, fileHandler);
    const missing = current.length < 1; // no current file found, latest archive should be moved to current section
    const archive = current.length > 1; // current files are more than 1
    //console.log(missing);
    current = missing ? [expired.shift()] : current;
    current = current.length > 0 && current[0] === undefined ? [] : current;

    const currentExpired = archive ? current.splice(1) : [];
    const expiredMerge = archive ? currentExpired.concat(expired) : expired;
    const files = {
      future: future,
      current: current,
      expired: expiredMerge
    };
    return files;
    */
  }
};
