// vuex store module
import StringHandler from "@/plugins/StringHandler";
import docsHandler from "@/plugins/DocumentsHandler";
import docHandler from "@/plugins/DocumentHandler";
//import objHandler from "@/plugins/ObjectHandler.js";

export default function storeSearchDocs() {
  return {
    // mark as namespaced
    namespaced: true,

    // storing the loaded api properties of our application
    // the apiMenu is storing response from menu api
    // the apiPage is storing response from page api returning the content of one page
    state: {
      searchText: "",
      searchYear: null
    },

    // getter for the state properties are used for accessing them
    getters: {
      getSearchText: function(state) {
        return state.searchText;
      },

      isValidSearchText: function(state) {
        return state.searchText.length > 2;
      },

      // returns the docs with the searchText in any of their labels
      foundDocs: function(state, getters, rootState, rootGetters) {
        return getters.isValidSearchText
          ? docsHandler.searchDocumentsByLabel(
              rootGetters["documents/getDocs"],
              state.searchText
            )
          : [];
      },

      // returns the years in the found docs
      foundYears: function(state, getters) {
        return docsHandler.getYearsInDocuments(getters.foundDocs);
      },

      getSearchYear: function(state) {
        return state.searchYear;
      },

      searchResult: function(state, getters) {
        if (state.searchYear === null) {
          return getters.foundDocs;
        }
        return getters.foundDocs.filter(function(doc) {
          const yearsInDoc = docHandler.getYearsInDocument(doc);
          return yearsInDoc.indexOf(state.searchYear) >= 0;
        }, this);
      },

      searchResultLabels: function(state, getters) {
        return docsHandler.getLabelsWithCharsFromDocs(
          getters.searchResult,
          state.searchText
        );
      }
    },

    // mutating, changing the the state properties
    // !!! IMPORTANT: mutations are and should be always syncronous
    // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
    mutations: {
      // changes the apiMenu state by replacing it with a new array
      setSearchText: function(state, newValue) {
        state.searchText = StringHandler.forceString(newValue);
      },

      setSearchYear: function(state, newValue) {
        const val = newValue + 0 === newValue ? newValue : null;
        state.searchYear = val;
      }
    },

    // actions. methods for triggering mutations
    actions: {
      changeSearchText: function(context, newValue) {
        context.commit("setSearchText", newValue);
      },

      changeSearchYear: function(context, newValue) {
        context.commit("setSearchYear", newValue);
      },

      resetSearchYear: function(context) {
        context.commit("setSearchYear", null);
      }
    }
  };
}
