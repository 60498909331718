<template>
  <div class="uins-doc-label">
    <SearchIcon></SearchIcon>
    <input
      class="uins-doc-search"
      type="text"
      v-model.trim="searchText"
      placeholder="dokumentum keresés"
    />
    <SearchYears></SearchYears>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SearchYears from "@/components/SearchYears.vue";
import SearchIcon from "@/components/SearchIcon.vue";

export default {
  name: "SearchDocuments",
  components: {
    SearchYears: SearchYears,
    SearchIcon: SearchIcon
  },
  created: function() {},
  props: {},
  // component data properties
  data: function() {
    return {};
  },

  // component computed values
  computed: {
    ...mapGetters("documents", {
      getUnionDocsFiltered: "getUnionDocsFiltered",
      getDocs: "getDocs"
    }),
    ...mapGetters("parameters", {
      getParameters: "getParameters"
    }),
    ...mapGetters("searchDocs", {
      getSearchText: "getSearchText",
      isValidSearchText: "isValidSearchText",
      foundDocs: "foundDocs",
      foundYears: "foundYears"
    }),

    searchText: {
      get: function() {
        return this.getSearchText;
      },
      set: function(value) {
        this.changeSearchText(value);
      }
    },

    shouldSearch: function() {
      return this.isValidSearchText;
    }
  },

  methods: {
    ...mapActions("searchDocs", {
      changeSearchText: "changeSearchText"
    })
  }
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally
.uins-doc-label {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1rem;
  text-align: center;
  //min-width: 18em;
  min-width: 80%;
  max-width: 32em;
  height: auto;
  margin: 0px auto 0px auto;
  padding: 0 5em 0 0;
  border: none;

  .uins-doc-search {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 1.25em;
    width: 100%;
    height: 2.1em;
    margin: 0px;
    padding: 0.25em 0.25em 0.25em 2.25em;
    //vertical-align: middle;
    font-family: $font-family-light;
    color: $colorGrayDark;
    line-height: 110%;
    border-top: 1px solid $colorGrayLight;
    border-right: none;
    border-bottom: 1px solid $colorGrayLight;
    border-left: 1px solid $colorGrayLight;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    //border-top-left-radius: 4px;
    //border-bottom-left-radius: 4px;

    &::placeholder {
      color: $colorGrayLight;
    }

    &:focus {
      outline-style: none;
      box-shadow: none;
      border-color: $colorRed;
    }
  }
}
</style>
