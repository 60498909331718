import Vue from "vue";
import Vuex from "vuex";
import storeLoader from "@/store/modules/storeLoader";
import storeLoaderPrivate from "@/store/modules/storeLoaderPrivate";
import storeApi from "@/store/modules/storeApi";
import storeSearchDocs from "@/store/modules/storeSearchDocs";
import storeSearchPages from "@/store/modules/storeSearchPages";
import storeParameters from "./modules/storeParameters";
import storeDocuments from "./modules/storeDocuments";
import storeMode from "./modules/storeMode";
Vue.use(Vuex);

export default function makeStore(data) {
  return new Vuex.Store({
    // adding pugins
    //plugins: [ObjectHandler],

    // storing all the properties of our application
    state: {},

    // getter for the state properties are used for accessing them
    getters: {},

    // mutating, changing the the state properties
    // !!! IMPORTANT: mutations are and should be always syncronous
    // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
    mutations: {},

    // actions. methods for triggering mutations
    actions: {},

    //modules with separate store data structure
    modules: {
      parameters: storeParameters(data),
      mode: storeMode(),
      loader: storeLoader,
      loaderPrivate: storeLoaderPrivate(),
      api: storeApi,
      searchDocs: storeSearchDocs(),
      searchPages: storeSearchPages(),
      documents: storeDocuments()
    }
  });
}
