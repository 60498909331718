<template>
  <slide-up-down
    tag="div"
    class="uins-result-count"
    :active="shouldShowResultCount"
    >{{ resultText }}
  </slide-up-down>
</template>

<script>
//import Document from "@/components/Document.vue";
import { mapGetters } from "vuex";

export default {
  name: "ResultCount",
  components: {},

  data: function() {
    return {
      textNoResults: "Nincs találat.",
      textPagesResult: "Talált oldalak száma: ",
      textDocumentsResult: "Talált dokumentumok száma: "
    };
  },

  // component computed values
  computed: {
    ...mapGetters("searchDocs", {
      isValidDocumentSearchText: "isValidSearchText",
      searchResult: "searchResult"
    }),
    ...mapGetters("searchPages", {
      isValidPageSearchText: "isValidSearchText",
      getFilteredPageHits: "getFilteredPageHits"
    }),
    ...mapGetters("mode", {
      isSearchModeDocs: "isSearchModeDocs",
      isSearchModePages: "isSearchModePages",
      isSearchDisabled: "isSearchDisabled"
    }),
    ...mapGetters("loader", {
      isGlobalLoding: "isLoading"
    }),
    ...mapGetters("loaderPrivate", {
      isPrivateLoding: "isLoading"
    }),

    // noResultsFound: function() {
    //   return this.isValidSearchText && this.searchResult.length === 0;
    // },

    isLoading: function() {
      return this.isGlobalLoding || this.isPrivateLoding;
    },

    shouldShowResultCount: function() {
      return (
        this.shouldShowDocumentResultCount || this.shouldShowPageResultCount
      );
    },

    shouldShowDocumentResultCount: function() {
      return (
        this.isValidDocumentSearchText &&
        this.isSearchModeDocs &&
        !this.isLoading
      );
    },

    shouldShowPageResultCount: function() {
      return (
        this.isValidPageSearchText && this.isSearchModePages && !this.isLoading
      );
    },

    countDocumentSearchResults: function() {
      return this.searchResult.length;
    },

    countPageSearchResults: function() {
      return this.getFilteredPageHits.length;
    },

    resultText: function() {
      if (this.isSearchModeDocs) return this.resultDocumentsText;
      if (this.isSearchModePages) return this.resultPagesText;
      return "";
    },

    resultDocumentsText: function() {
      return this.countDocumentSearchResults > 0
        ? this.textDocumentsResult + this.countDocumentSearchResults
        : this.textNoResults;
    },

    resultPagesText: function() {
      return this.countPageSearchResults > 0
        ? this.textPagesResult + this.countPageSearchResults
        : this.textNoResults;
    }
  }
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally

.uins-result-count {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0 auto 0 auto;
  padding: 1em 0px 0px 0px;
  text-align: center;
  max-width: 100%;
  line-height: normal;
}
</style>
