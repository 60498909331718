// vuex store module
// intended for api functionality, used in conjunction with axios for loading api
import LoadService from "@/services/LoadService";
import ObjectHandler from "@/plugins/ObjectHandler";
import axios from "axios";

export default {
  // mark as namespaced
  namespaced: true,

  // storing the loaded api properties of our application
  // the apiMenu is storing response from menu api
  // the apiPage is storing response from page api returning the content of one page
  state: {
    apiDocumentsWeb: [],
    apiDocsSmartCube: [],
    loading: 0
    //cancelToken: axios.CancelToken.source(),
  },

  // getter for the state properties are used for accessing them
  getters: {
    // returns the current value of the apiDocuments state
    getApiDocumentsWeb: function(state) {
      return state.apiDocumentsWeb;
    },

    getApiDocsSmartCube: function(state) {
      return state.apiDocsSmartCube;
    }
  },

  // mutating, changing the the state properties
  // !!! IMPORTANT: mutations are and should be always syncronous
  // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
  mutations: {
    // changes the apiMenu state by replacing it with a new array
    setApiDocumentsWeb: function(state, newArray) {
      state.apiDocumentsWeb = ObjectHandler.returnArray(newArray);
    },
    setApiDocsSmartCube: function(state, newArray) {
      state.apiDocsSmartCube = ObjectHandler.returnArray(newArray);
    },
    setLoading: function(state) {
      state.loading++;
    },
    unsetLoading: function(state) {
      state.loading = Math.max(0, state.loading - 1);
    }
  },

  // actions. methods for triggering mutations
  actions: {
    // loads asynchronously the apiMenu
    async loadDocumentsFromWeb(context, { sendData, store }) {
      // gets acynchronously the documents XML
      // saves the recieved value to state by commiting mutation
      const response = await LoadService.getDocsUnionWebApi(sendData, store);
      context.commit("setApiDocumentsWeb", response.data);
      // dispatching action is used for calling an action
      // context.dispatch("actionName");
    },

    async loadDocsFromSmartCube(context, { sendData, store }) {
      // gets acynchronously the documents XML
      // saves the recieved value to state by commiting mutation
      const response = await LoadService.getDocsSmartCubeApi(sendData, store);
      context.commit("setApiDocsSmartCube", response.data);
      // dispatching action is used for calling an action
      // context.dispatch("actionName");
    },

    async loadDocs(context, { sendData, store }) {
      if (context.state.loading < 1) {
        context.commit("setLoading");
        try {
          const [responseSmartCube, responseUnion] = await axios.all([
            LoadService.getDocsSmartCubeApi(sendData, store),
            LoadService.getDocsUnionWebApi(sendData, store)
          ]);
          context.commit("unsetLoading");
          //console.log(responseSmartCube.data, responseUnion.data);
          context.commit("setApiDocsSmartCube", responseSmartCube.data);
          context.commit("setApiDocumentsWeb", responseUnion.data);
        } catch (err) {
          //do nothing
          context.commit("unsetLoading");
          //console.log(err);
        }
      }
    }
  }
};
