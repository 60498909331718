// string utilities
// methods for searching and handling strings

export default {
  /**
   * Checks a variable is string and returns a forced string value
   * @param {string} str value beeing checked.
   * @return {string} returns the variable if is string or an empty string otherwise
   */
  forceString: function(str) {
    return str === str + "" ? str : "";
  },

  /**
   * Checks if a string contains another string
   * @param {string} str value beeing checked
   * @param {string} searchText value beeing searched for in str
   * @return {boolean} returns true or false
   */
  searchStringForText: function(str, searchText) {
    const escapedText = this.escapeRegExp(searchText);
    if (escapedText === "") return false;
    const regex = new RegExp(escapedText, "gi");
    const checkedStr = this.forceString(str);
    return regex.test(checkedStr);
  },

  /**
   * Escapes  reserved REGEX characters in a string
   * @param {string} str value beeing escaped
   * @return {string} returns string with escaped characters, ready to be used in REGEX expressions
   */
  escapeRegExp: function(str) {
    const checkedStr = this.forceString(str);
    return checkedStr.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  },

  // remove unneeded words and stringparts from title string
  // this is usefull if searching for words in titles
  cleanTitle: function(title) {
    const strTitle = title + "" === title ? title : "";
    const reg = new RegExp(
      "\\(|\\)|\"|'|„|”|-,|–,|,| -| –|- és |– és | a | és | az | vagy ",
      "gi"
    );
    return strTitle.replace(reg, " ");
  },

  getWordsWithCharsFromString: function(str, chars) {
    const cleanStr = this.cleanTitle(str);
    //console.log(cleanStr);
    const strArray = cleanStr.toUpperCase().split(" ");
    const escChars = this.escapeRegExp(chars);
    const chrReg = new RegExp(".*" + escChars, "gi"); //new RegExp("^" + escChars + "|.*[\\-|–].*" + escChars, "gi");
    var keys = {};
    const filtered = strArray.filter(function(word) {
      const isValid = chrReg.test(word);
      const isFound = Object.prototype.hasOwnProperty.call(keys, word);
      if (!isFound && isValid) {
        keys[word] = true;
      }
      return isValid && !isFound;
    }, this);
    const ordered = filtered
      .slice()
      .sort(this.orderAlphabetically(this.hungarianAphabet), this);
    return ordered;
  },

  //returns true if chars string is found inside word (case sensitive)
  wordHasChars: function(word, chars) {
    const index = chars !== "" ? word.indexOf(chars) : -1;
    return index >= 0;
  },

  orderAlphabetically: function(alphabet, dir, caseSensitive) {
    dir = dir || 1;
    caseSensitive = caseSensitive || false;

    function compareLetters(a, b) {
      var ia = alphabet.indexOf(a);
      var ib = alphabet.indexOf(b);
      if (ia === -1 || ib === -1) {
        if (ib !== -1) return a > "a";
        if (ia !== -1) return "a" > b;
        return a > b;
      }
      return ia > ib;
    }
    return function(strA, strB) {
      var pos = 0;
      const a = !caseSensitive ? strA.toLowerCase() : strA;
      const b = !caseSensitive ? strB.toLowerCase() : strB;
      const min = Math.min(a.length, b.length) - 1;
      while (a.charAt(pos) === b.charAt(pos) && pos < min) {
        pos++;
      }
      if (a.charAt(pos) === b.charAt(pos) && pos === min) {
        return a.length > b.length ? dir : -dir;
      }
      return compareLetters(a.charAt(pos), b.charAt(pos)) ? dir : -dir;
    };
  },

  /** @constant
   *  @type {string} string with the hungarian aplhabet's letters, ordered
   *  @default
   *  this is used for ordering alpahabetically
   */
  hungarianAphabet:
    "AaÁáBbCcDdEeÉéFfGgHhIiÍíJjKkLlMmNnOoÓóÖöŐőPpQqRrSsTtTytyUuÚúÜüŰűVvWwXxYyZz"
};
