// creating plugin
// returns an object with properties or methods
// contains methods for generating text suffixes for numbers, for dates, month names

//import objHandler from "@/plugins/ObjectHandler.js";
export default {
  forceInt: function(num) {
    const myNum = isNaN(num) ? parseInt(num, 10) : num;
    return isNaN(myNum) ? 0 : myNum;
  },

  isMonthNumber: function(num) {
    return (
      !isNaN(num) &&
      isFinite(num) &&
      Math.floor(num) === num &&
      num >= 1 &&
      num <= 12
    );
  },

  isDate8Digit: function(num) {
    return (
      !isNaN(num) &&
      isFinite(num) &&
      Math.floor(num) === num &&
      num >= 10000000 &&
      num <= 99999999
    );
  },

  getDayNumFromDate8Digit: function(date8Digit) {
    const myInt = this.forceInt(date8Digit);
    return myInt % 100;
  },

  getMonthNumFromDate8Digit: function(date8Digit) {
    const myInt = this.forceInt(date8Digit);
    return Math.floor(myInt / 100) % 100;
  },

  getYearNumFromDate8Digit: function(date8Digit) {
    const myInt = this.forceInt(date8Digit);
    return Math.floor(myInt / 10000);
  },

  formatYearlessDateHunFromDate8Digit: function(date8Digit, short) {
    const month = this.getMonthNumFromDate8Digit(date8Digit) - 1; //js month date indexes are beginning with 0
    const monthName =
      short === true ? this.getShortMonthName(month) : this.getMonthName(month);
    const day = this.getDayNumFromDate8Digit(date8Digit);
    return monthName + " " + day;
  },

  formatHunFromDate8Digit: function(date8Digit, short) {
    const year = this.getYearNumFromDate8Digit(date8Digit);
    return (
      year + ". " + this.formatYearlessDateHunFromDate8Digit(date8Digit, short)
    );
  },

  monthNamesHun: [
    "január",
    "február",
    "március",
    "április",
    "május",
    "június",
    "július",
    "augusztus",
    "szeptember",
    "október",
    "november",
    "december"
  ],

  shortMonthNamesHun: [
    "jan.",
    "feb.",
    "már.",
    "ápr.",
    "máj.",
    "júni.",
    "júli.",
    "aug.",
    "szep.",
    "okt.",
    "nov.",
    "dec."
  ],

  getMonthName: function(num) {
    return this.monthNamesHun[num] || "";
  },

  getShortMonthName: function(num) {
    return this.shortMonthNamesHun[num] || "";
  },

  suffixesHun: {
    bol: ["ból", "ből"],
    tol: ["tól", "től"],
    ban: ["ban", "ben"]
  },

  getNumberSuffixHun: function(num, suffixType) {
    const suffixes = this.suffixesHun[suffixType] || this.suffixesHun.bol;
    const numCheck = num + "" === num || num === num + 0 ? num + "" : "";
    const regex = /^(0|[\d]*[368]|[\d]*[2368]+0|[\d]?[1-9]00|[1-9][\d]{0,2}0{6,9})$/;
    return regex.test(numCheck) ? suffixes[0] : suffixes[1];
  }
};
