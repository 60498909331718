// date helper methods

export default {
  // returns today date in the form of YYYYMMDD
  getTodayInt: function() {
    const today = new Date();
    return (
      today.getFullYear() * 10000 +
      (today.getMonth() + 1) * 100 +
      today.getDate()
    );
  }
};
