<template>
  <div class="choice" :class="{ active: isSelected }" @click="clickChoice">
    {{ buttonTextBig }}<span class="suffix">{{ buttonTextSmall }}</span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import textGenerator from "@/plugins/TextGenerator.js";

export default {
  name: "SearchYearsChoice",
  components: {},
  created: function() {},
  props: {
    year: {
      default: null,
      type: Number
    }
  },

  // component data properties
  data: function() {
    return {
      allYears: "Bármikor hatályos",
      inEffect: "hatályos"
    };
  },

  // component computed values
  computed: {
    ...mapGetters("searchDocs", {
      getSearchYear: "getSearchYear"
    }),

    isYear: function() {
      return this.year !== null && this.year + 0 === this.year;
    },

    buttonTextBig: function() {
      return this.isYear ? this.year + "" : "";
    },

    buttonTextSmall: function() {
      return this.isYear
        ? this.yearSuffix + " " + this.inEffect
        : this.allYears;
    },

    yearSuffix: function() {
      return this.isYear
        ? "-" + textGenerator.getNumberSuffixHun(this.year, "ban")
        : "";
    },

    isSelected: function() {
      return this.year === this.getSearchYear;
    }
  },

  methods: {
    ...mapActions("searchDocs", {
      changeSearchYear: "changeSearchYear"
    }),

    clickChoice: function() {
      if (this.getSearchYear !== this.year) {
        this.changeSearchYear(this.year);
      }
      this.$emit("clickChoice");
    }
  }
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally
.uins-doc-search:focus + .uins-doc-search-years {
  .button {
    border-color: $colorRed;
    border-left: none;
  }
}

.uins-doc-search-years {
  .choices {
    .choice {
      position: relative;
      display: block;
      box-sizing: border-box;
      font-size: 0.85em;
      font-family: $font-family-normal;
      line-height: 100%;
      text-transform: uppercase;
      color: $colorGray;
      width: auto;
      height: auto;
      min-height: 2.625em;
      margin: 0px 1px 0px 1px;
      padding: 0.35em 0.5em 0.25em 0.5em;
      border-top: 1px solid $colorGrayLighter;
      cursor: pointer;

      &:first-child {
        border-top: none;
      }

      .suffix {
        position: relative;
        display: inline;
        line-height: 100%;
        font-size: 0.75em;
        margin: 0px;
        padding: 0px;
        color: inherit;
      }

      &:hover {
        background-color: $colorGrayLightest;
      }

      &.active {
        color: $colorWhite;
        background-color: $colorGray;
      }
    }
  }
}
</style>
