<template>
  <div class="uins-page">
    <a v-html="page.name" class="uins-page-title" v-bind:href="page.url"></a>
    <span v-html="page.highlighted" class="uins-page-content"></span>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  created: function() {},

  mounted: function() {
    if (this.observe) {
      try {
        this.addIntersectionObserver();
      } catch {
        this.requestAllResults();
      }
    }
  },

  props: {
    page: {
      type: Object,
      required: true,
      default: function() {
        return {};
      }
    },
    observe: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  // component data properties
  data: function() {
    return {};
  },

  // component computed values
  computed: {},

  filters: {},

  methods: {
    getIntersectionObserverOptions: function() {
      return {
        root: null,
        rootMargin: "0px",
        threshold: 0
      };
    },

    addIntersectionObserver: function() {
      let observer = new IntersectionObserver(
        this.handleIntersect,
        this.getIntersectionObserverOptions()
      );
      observer.observe(this.$el);
    },

    handleIntersect: function(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // observing should be stopped
          // parent should be notified, to show more results
          observer.unobserve(this.$el);
          this.requestMoreResults();
          //this.$log.debug(observer, "is intersecting");
        }
      });
    },

    requestMoreResults: function() {
      this.$emit("requestingMoreResults");
    },

    requestAllResults: function() {
      this.$emit("requestingAllResults");
    }
  },

  watch: {}
};
</script>

<style lang="scss">
.uins-page {
  position: relative;
  display: block;
  box-sizing: border-box;

  font-size: 1em;
  max-width: 30em;
  margin: 0 auto 0 auto;
  padding: 2em 0 2em 0;
  border-top: 1px solid $colorGrayLight;
  color: $colorGray;
  line-height: normal;

  &:first-child {
    border-top: none;
  }

  .uins-page-title {
    position: relative;
    display: block;
    box-sizing: border-box;

    font-size: 1.25em;
    margin: 0;
    padding: 0 0 0.75em 0;
    font-family: $font-family-light;
    font-weight: normal;
    color: inherit;
    line-height: inherit;
    max-height: 2.25em;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .uins-page-content {
    position: relative;
    display: block;
    box-sizing: border-box;

    font-size: 1em;
    margin: 0;
    padding: 0;
    font-family: $font-family-light;
    font-weight: normal;
    color: inherit;
    line-height: inherit;

    .found {
      font-family: $font-family-normal;
    }

    b {
      font-family: $font-family-normal;
      font-weight: normal;
      color: $colorGrayDarkest;
    }
  }

  &:hover,
  &:active {
    color: $colorBlack;
  }
}
</style>
