<template>
  <slide-up-down :active="shouldShow" :duration="500">
    <div class="loader">
      <img
        :src="`${appUrl}assets/img/u-loader.gif`"
        class="image"
        alt="adatok letöltése folyamatban..."
      />
    </div>
  </slide-up-down>
</template>

<script>
import { EventBus } from "@/main.js";
import { mapGetters } from "vuex";

export default {
  name: "Loader",
  created: function() {},

  // component data properties
  data: function() {
    return {
      appUrl: process.env.VUE_APP_URL
    };
  },

  // component computed values
  computed: {
    ...mapGetters("loader", {
      isGlobalLoding: "isLoading"
    }),

    ...mapGetters("loaderPrivate", {
      isPrivateLoding: "isLoading"
    }),

    // returns true if data loading has at least one property with true value
    shouldShow: function() {
      EventBus.$emit(this.loadStatus);
      return this.loadInProgress;
    },

    //rtreurns true or false dependig if there is currently a load in progress or not
    loadInProgress: function() {
      return this.isGlobalLoding || this.isPrivateLoding;
      /* return (
        this.$store.state.loaderPrivate.loading +
          this.$store.state.loader.loading >
        0
      );
      */
    },

    // returns the load status event name used for emitting on EventBus
    loadStatus: function() {
      return this.loadInProgress === true ? "loadStarted" : "loadFinished";
    }
  },

  filters: {},

  methods: {},

  watch: {}
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally

.loader {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0px;
  padding: 0.5em 0px 1em 0px;
  width: 100%;

  .image {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 1em;
    width: 10em;
    height: auto;
    margin: 0px auto 0px auto;
    padding: 0px;
  }
}
</style>
