<template>
  <div class="uins-search-mode" v-if="canChangeSearchMode">
    <span
      class="button-mode"
      v-bind:class="{ active: isSearchModePages }"
      v-on:click="switchToSearchModePages"
      >Keresés az oldalban</span
    ><span
      class="button-mode"
      v-bind:class="{ active: isSearchModeDocs }"
      v-on:click="switchToSearchModeDocs"
      >Dokumentum keresés</span
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ModeChoice",

  // component data properties
  data: function() {
    return {};
  },

  // computed properties
  computed: {
    ...mapGetters("mode", {
      canChangeSearchMode: "canChangeSearchMode",
      isSearchModeDocs: "isSearchModeDocs",
      isSearchModePages: "isSearchModePages"
    })
  },

  // component methods
  methods: {
    ...mapActions("mode", {
      switchToSearchModePages: "switchToSearchModePages",
      switchToSearchModeDocs: "switchToSearchModeDocs"
    })
  }
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally
.uins-docs {
  .uins-search-mode {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 0.75em;
    margin: 0 auto 0 auto;
    padding: 0 0 1em 0;

    .button-mode {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      font-size: inherit;

      margin: 0px;
      padding: 0.5em 1em 0.45em 1em;
      text-transform: uppercase;
      text-align: center;
      font-family: "LatoWeb", Helvetica, sans-serif;
      color: $colorGrayDark;
      border: 1px solid $colorGrayLight;
      //background-color: $colorGrayLight;
      z-index: 1;
      //border-radius: 3px;
      //border: 1px solid $colorGrayLight;
      line-height: 100%;
      cursor: pointer;
      transition-duration: 0.25s;
      transition-property: color, background-color, border-color;

      &:hover {
        border-color: $colorGray;
        color: $colorWhite;
        background-color: $colorGray;
      }

      &.active {
        color: $colorWhite;
        background-color: $colorGrayLight;
        border-color: $colorGrayLight;
        cursor: default;
      }

      & + .button-mode {
        border-left: none;
      }

      &:first-child {
        border-top-left-radius: 0.25em;
        border-bottom-left-radius: 0.25em;
      }

      &:last-child {
        border-top-right-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .uins-search-mode {
      .button-mode {
        display: block;
        width: 30ch;
        max-width: 80%;
        margin: 0 auto 0 auto;

        & + .button-mode {
          border-left: 1px solid $colorGrayLight;

          &:active {
            border-left: 1px solid $colorGrayLight;
          }
        }

        &:first-child {
          border-top-right-radius: 0.25em;
          border-bottom-left-radius: 0;
        }

        &:last-child {
          border-top-right-radius: 0em;
          border-bottom-left-radius: 0.25em;
        }
      }
    }
  }
}
</style>
