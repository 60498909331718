// vuex store module
import LoadService from "@/services/LoadService";
import StringHandler from "@/plugins/StringHandler";
import ObjHandler from "@/plugins/ObjectHandler.js";
import PageSearchHandler from "@/plugins/PageSearchHandler";

function getPageModel() {
  return {
    name: "",
    highlighted: "",
    url: ""
  };
}
export default function storeSearchPages() {
  return {
    // mark as namespaced
    namespaced: true,

    // storing the loaded api properties of our application
    // the apiMenu is storing response from menu api
    // the apiPage is storing response from page api returning the content of one page
    state: {
      searchText: "",
      apiPagesResponse: {}
    },

    // getter for the state properties are used for accessing them
    getters: {
      getSearchText: function(state) {
        return state.searchText;
      },

      isValidSearchText: function(state) {
        return state.searchText.length > 2;
      },

      getUrlEncodedSearchText: function(state) {
        return encodeURIComponent(state.searchText);
      },

      getSearchParameters: function(state, getters) {
        return (
          "type=document&size=200&query=" + getters.getUrlEncodedSearchText
        );
      },

      getPageHits: function(state) {
        return ObjHandler.getProp([], "hits", state.apiPagesResponse);
      },

      getFilteredPageHits: function(state, getters) {
        return getters.getPageHits.reduce(function(result, item) {
          const url = ObjHandler.getProp("", "url", item);
          const isValidUrl = PageSearchHandler.isSearchableUrl(url); //true or false
          if (!isValidUrl) return result;

          const title = ObjHandler.getProp("", "name", item);
          const filteredTitle = PageSearchHandler.filterTitle(title); //filtered or empty string
          const isValidTitle = filteredTitle.length > 0;
          if (!isValidTitle) return result;

          const content = ObjHandler.getProp("", "highlighted", item);
          const filteredContent = PageSearchHandler.filterContent(content); //filtered or empty string
          const isValidContent = filteredContent.length > 0;
          if (!isValidContent) return result;

          const page = getPageModel();
          page.name = filteredTitle;
          page.highlighted = filteredContent;
          page.url = url;
          result.push(page);

          return result;
        }, []);
      }
    },

    // mutating, changing the the state properties
    // !!! IMPORTANT: mutations are and should be always syncronous
    // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
    mutations: {
      // changes the apiMenu state by replacing it with a new array
      setSearchText: function(state, newValue) {
        state.searchText = StringHandler.forceString(newValue);
      },
      setApiPagesResponse: function(state, newValue) {
        state.apiPagesResponse = ObjHandler.forceObject(newValue);
      }
    },

    // actions. methods for triggering mutations
    actions: {
      changeSearchText: function(context, newValue) {
        context.commit("setSearchText", newValue);
      },

      loadPages: function(context, { store }) {
        if (context.getters.isValidSearchText) {
          context.dispatch("loadPagesFromApi", { store });
        } else {
          context.commit("setApiPagesResponse", {});
        }
      },

      async loadPagesFromApi(context, { store }) {
        const searchText = context.state.searchText;
        const parameters = context.getters.getSearchParameters;
        try {
          const response = await LoadService.getPagesUnionApi(
            parameters,
            store
          );
          const data = ObjHandler.getProp({}, "data", response);
          if (searchText === context.state.searchText) {
            context.commit("setApiPagesResponse", data);
          }
        } catch (err) {
          //do nothing
          context.commit("setApiPagesResponse", {});
          //console.log(err);
        }
      }
    }
  };
}
