<template>
  <div class="uins-doc">
    <!--future releases-->

    <DocumentFile
      v-for="(file, index) in docFilesFuture"
      v-bind:key="'future' + index"
      v-bind:file="file"
      v-bind:docinfo="docInfo"
      class="planned"
    ></DocumentFile>

    <!--current release-->

    <div class="uins-current">
      <DocumentFile
        v-for="(file, index) in docFilesCurrent"
        v-bind:key="'current' + index"
        v-bind:file="file"
        v-bind:docinfo="docInfo"
        :class="{ archive: currentFileIsExpired }"
      ></DocumentFile>

      <!--template v-if="yearFilterIsEnabled">
        <DocumentFile
          v-for="(file, index) in docFilesExpired"
          v-bind:key="'expired' + index"
          v-bind:file="file"
          v-bind:docinfo="docInfo"
          data-inEffect="archive"
          class="archive"
        ></DocumentFile>
      </template-->

      <!--archive-->
      <ArchiveButton
        v-if="visibleArchiveButton"
        @archiveButton-clicked="archiveButtonClick"
        v-bind:years="expiredYears"
      ></ArchiveButton>

      <slide-up-down
        v-if="visibleArchiveButton"
        :active="visibleArchive"
        :duration="500"
        class="doc-archive"
      >
        <!--div class="doc-archive clearfix"-->

        <DocumentFile
          v-for="(file, index) in docFilesExpired"
          v-bind:key="'archive' + index"
          v-bind:file="file"
          v-bind:docinfo="docInfo"
          class="archive"
        ></DocumentFile>

        <!--/div-->
      </slide-up-down>
    </div>
  </div>
</template>

<script>
import DocumentFile from "@/components/DocumentFile.vue";
import ArchiveButton from "@/components/ArchiveButton.vue";

import { mapGetters } from "vuex";

import objHandler from "@/plugins/ObjectHandler.js";
import filesHandler from "@/plugins/FilesHandler.js";
import fileHandler from "@/plugins/FileHandler.js";

export default {
  name: "Document",
  components: {
    DocumentFile: DocumentFile,
    ArchiveButton: ArchiveButton
  },
  created: function() {},

  mounted: function() {
    if (this.observe) {
      //this.$log.debug("should Observe");
      try {
        this.addIntersectionObserver();
      } catch {
        //this.$log.debug("intersection observer not working");
        this.requestAllResults();
      }
    }
  },

  props: {
    doc: {
      type: Object,
      required: true,
      default: function() {
        return {};
      }
    },
    observe: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  // component data properties
  data: function() {
    return {
      archiveIsVisible: false
    };
  },

  // component computed values
  computed: {
    ...mapGetters("parameters", {
      getParameters: "getParameters"
    }),

    ...mapGetters("searchDocs", {
      getSearchYear: "getSearchYear"
    }),

    docInfo: function() {
      return {
        docId: this.docId,
        title: this.docTitle,
        labels: this.docLabels,
        showInfo: this.showInfo,
        fromUnion: this.isFromUnion
      };
    },

    isFromUnion: function() {
      return objHandler.getProperty(false, "fromUnion", this.doc);
    },

    // returns true if source and id should be shown
    showInfo: function() {
      return this.getParameters.showInfo;
    },

    docTitle: function() {
      return objHandler.getProperty("", "title", this.doc);
    },

    docFiles: function() {
      //return objHandler.getProperty([], "files", this.doc);
      const files = objHandler.getProperty([], "files", this.doc);
      // exclude the files not maching the searchYear
      return files.filter(function(file) {
        return this.fileIsForYear(file);
      }, this);
    },

    hasFiles: function() {
      return this.docFiles.length > 0;
    },

    docFilesOrdered: function() {
      return filesHandler.orderFiles(this.docFiles);
    },

    docFilesSorted: function() {
      return filesHandler.sortFiles(this.docFilesOrdered);
    },

    docFilesFuture: function() {
      return this.docFilesSorted.future || [];
    },

    docFilesCurrent: function() {
      return this.docFilesSorted.current || [];
    },

    docFilesExpired: function() {
      return this.docFilesSorted.expired || [];
    },

    docLabels: function() {
      return objHandler.getProperty([], "labels", this.doc);
    },

    docId: function() {
      return objHandler.getProperty("", "id", this.doc);
    },

    visibleArchive: function() {
      return this.visibleArchiveButton && this.archiveIsVisible;
      /*
      return (
        this.archiveIsVisible &&
        this.docFilesSorted.expired.length > 0 &&
        !this.yearFilterIsEnabled
      );*/
    },

    visibleArchiveButton: function() {
      return this.hasArchive;
      //return !this.yearFilterIsEnabled && this.hasArchive;
    },

    currentFileIsExpired: function() {
      return this.docFilesCurrent.length > 0
        ? fileHandler.isExpired(this.docFilesCurrent[0])
        : false;
    },

    hasArchive: function() {
      return this.docFilesSorted.expired.length > 0;
    },

    yearFilterIsEnabled: function() {
      return this.getSearchYear !== null && isFinite(this.getSearchYear);
    },

    expiredYears: function() {
      const years = filesHandler.getYears(this.docFilesSorted.expired);
      const noDuplicates = objHandler.filterDuplicateValues(years);
      const ordered = noDuplicates.slice().sort(function(a, b) {
        return b - a;
      });
      return ordered;
    }
  },

  filters: {},

  methods: {
    archiveButtonClick: function(val) {
      //this.$log.debug("archive button clicked: ", val);
      this.archiveIsVisible = val;
    },

    fileIsForYear: function(file) {
      //this.$log.debug(file, this.filterYear);
      return this.yearFilterIsEnabled
        ? fileHandler.hasYear(file, this.getSearchYear)
        : true;
    },

    getIntersectionObserverOptions: function() {
      return {
        root: null,
        rootMargin: "0px",
        threshold: 0
      };
    },

    addIntersectionObserver: function() {
      let observer = new IntersectionObserver(
        this.handleIntersect,
        this.getIntersectionObserverOptions()
      );
      observer.observe(this.$el);
    },

    handleIntersect: function(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // observing should be stopped
          // parent should be notified, to show more results
          observer.unobserve(this.$el);
          this.requestMoreResults();
          //this.$log.debug(observer, "is intersecting");
        }
      });
    },

    requestMoreResults: function() {
      this.$emit("requestingMoreResults");
    },

    requestAllResults: function() {
      this.$emit("requestingAllResults");
    }
  },

  watch: {}
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally
.uins-doc {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0px auto 0px auto;
  padding: 0px;
  width: auto;
  height: auto;
  //min-height: 4.5em;

  .uins-current {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 1em;
    margin: 0px;
    padding: 0px;
    width: auto;
    height: auto;
    //min-height: 0px;
  }

  .uins-archive {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 1em;
    margin: 0px;
    padding: 0px;
    width: auto;
    height: auto;
    //min-height: 0px;
  }

  .doc-archive {
    position: relative;
    display: block;
    box-sizing: content-box;
    margin: 0px;
    padding: 0.25em 0px 0px 4.25em;

    .uins-file {
      padding-right: 0px;
    }
  }
}
</style>
