// creates data object for containers

import Handler from "@/plugins/VueContainerHandler.js";
import UrlHelper from "@/plugins/UrlHelper.js";
import stringHandler from "@/plugins/StringHandler.js";

export default {
  idSmartCube: "SmartCube",
  idSearch: "Search",
  idSearchPages: "SearchPages",

  ContainerData: function(elem, id) {
    const ids = Handler.getParameterValue(elem, Handler.classDocumentId);
    const filterIds = this.processIds(ids);
    const filterLabels = this.processLabels(elem);
    const categories = Handler.getParameterValue(elem, Handler.classCategories);
    const search = this.hasSearch(ids);
    const searchPages = this.hasSearchPages(ids);
    const valid = this.isValid(ids);
    const showInfo = UrlHelper.getLocationParamValue("showInfo");
    const urlSearchText = UrlHelper.getLocationParamValue("search");
    const urlSearchMode = UrlHelper.getLocationParamValue("searchMode");
    const showInfoValue = showInfo === "yes" || showInfo === "1" ? true : false;
    return {
      id: id,
      valid: valid,
      search: search,
      searchPages: searchPages,
      filterIds: filterIds,
      filterLabels: filterLabels,
      categories: categories,
      showInfo: showInfoValue,
      urlSearchText: stringHandler.forceString(urlSearchText),
      urlSearchMode: stringHandler.forceString(urlSearchMode)
    };
  },

  isValid: function(idArray) {
    return idArray.indexOf(this.idSmartCube) >= 0;
  },

  hasSearch: function(idArray) {
    return idArray.indexOf(this.idSearch) >= 0;
  },

  hasSearchPages: function(idArray) {
    return idArray.indexOf(this.idSearchPages) >= 0;
  },

  processLabels: function(elem) {
    const labels = Handler.getParameterValue(elem, Handler.classLabels);
    return labels.reduce(function(result, val) {
      val = val === val + "" ? val : "";
      return result.concat(
        val.split("|").map(function(part) {
          part = part === part + "" ? part : "";
          return part.split("+");
        })
      );
    }, []);
  },

  processIds: function(idArray) {
    return idArray.filter(item => {
      return (
        item !== "" &&
        item !== this.idSmartCube &&
        item !== this.idSearch &&
        item !== this.idSearchPages
      );
    });
  }
};
