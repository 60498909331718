<template>
  <slide-up-down
    :active="visibleSuggestions"
    :duration="250"
    class="uins-doc-search-suggestion"
  >
    <div
      class="uins-suggested-label"
      v-for="(label, index) in searchResultLabels"
      v-bind:key="'label' + index"
      v-on:click="clickSuggestion(label)"
    >
      {{ label }}
    </div>
  </slide-up-down>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
//import objectHandler from "@/plugins/ObjectHandler.js";
//import DocumentsHandler from "@/plugins/DocumentsHandler.js";

export default {
  name: "SuggestedLabels",
  components: {},

  // component data properties
  data: function() {
    return {};
  },

  // component computed values
  computed: {
    ...mapGetters("searchDocs", {
      getSearchText: "getSearchText",
      searchResult: "searchResult",
      searchResultLabels: "searchResultLabels"
    }),

    hasSuggestions: function() {
      return this.searchResultLabels.length > 0;
    },

    firstSuggestion: function() {
      const myLabel = this.hasSuggestions ? this.searchResultLabels[0] : "";
      return myLabel + "" === myLabel ? myLabel : "";
    },

    hasSearchedSuggestion: function() {
      return (
        this.searchResultLabels.length === 1 &&
        this.firstSuggestion.toLowerCase() === this.getSearchText.toLowerCase()
      );
    },

    visibleSuggestions: function() {
      return this.hasSuggestions && !this.hasSearchedSuggestion;
    }
  },

  methods: {
    ...mapActions("searchDocs", {
      changeSearchText: "changeSearchText"
    }),

    clickSuggestion: function(word) {
      const strWord = word === word + "" ? word : "";
      if (strWord !== "") {
        this.changeSearchText(strWord);
      }
    }
  }
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally

.uins-doc-search-suggestion {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0.25em auto 0.25em auto;
  padding: 0px;
  width: auto;
  height: auto;

  .uins-suggested-label {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    font-size: 0.85em;
    line-height: 100%;
    margin: 0.25em;
    padding: 0.4em 1em 0.25em 1em;
    width: auto;
    height: auto;
    color: $colorWhite;
    background-color: $colorGrayLight;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background-color: $colorGray;
    }
  }
}
</style>
